import { Scene, MathUtils, LinearFilter, ACESFilmicToneMapping, RGBAFormat, BufferGeometry, BufferAttribute, sRGBEncoding, MeshBasicMaterial, Mesh, TextureLoader, PerspectiveCamera, Vector3, WebGLRenderer, WebGLMultisampleRenderTarget, WebGLRenderTarget, Clock, Color } from 'three/build/three.module.js';
import gsap from 'gsap/all';
import { threeToCannon, ShapeType } from 'three-to-cannon';
import * as CANNON from "cannon-es";
import { PointerLockControlsCannon } from "./PointerLockControlsCannon";
import { Sky } from './Sky';
import { RaycasterHover } from './RaycasterHover'
import { Animation } from './Animation'
import { Stats } from './Stats';

export class Starting {
    constructor() {

        this.stats = new Stats();
        this.menuKey = document.querySelector('.menu-key')
        this.menuKeyClose = document.querySelector('.close-wrapper')
        this.menuKeyClose2 = document.querySelector('.close')
        this.animation = new Animation(this.menuKeyClose);



        this.sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        // MOBILE
        this.mobileMode;



        this.mobileDetect();

        this.p1;
        this.p2;
        this.p3;


        // Gameloop
        this.last = 0;


        //WORKER - STEP 1 
        this.loaded_object;

        // const workerPhysics = new Worker(new URL('./workerPhysics.js', import.meta.url));

        //TEXTURES - STEP 2
        this.bakedTexture;

        // CANNON.JS
        this.sphereBody;
        this.sphereShape;
        this.radius = 1.3

        this.timeStep = 1 / 60
        this.lastCallTime = performance.now()

        this.world = new CANNON.World()
        this.world.broadphase = new CANNON.SAPBroadphase(this.world) //PERFORMANCE
        // world.broadphase = new CANNON.NaiveBroadphase();
        this.world.allowSleep = false;
        this.world.gravity.set(0, -30, 0)

        this.world.defaultContactMaterial.contactEquationStiffness = 1e9

        // Stabilization time in number of timesteps
        this.world.defaultContactMaterial.contactEquationRelaxation = 4

        this.defaultMaterial = new CANNON.Material('default')
        this.defaultContactMaterial = new CANNON.ContactMaterial(
            this.defaultMaterial,
            this.defaultMaterial,
            {
                friction: 0.0,
                restitution: 0.3,
            }
        )

        this.world.addContactMaterial(this.defaultContactMaterial)
        this.world.defaultContactMaterial = this.defaultContactMaterial

        this.solver = new CANNON.GSSolver()
        this.solver.iterations = 1
        this.solver.tolerance = 0.1
        this.world.solver = new CANNON.SplitSolver(this.solver)

        // Create the ground plane
        this.groundShape = new CANNON.Plane()
        this.groundBody = new CANNON.Body({ mass: 0, material: this.defaultContactMaterial })
        this.groundBody.addShape(this.groundShape)
        this.groundBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0)
        this.world.addBody(this.groundBody)

        // Create the user collision sphere
        this.sphereShape = new CANNON.Sphere(this.radius)
        this.sphereBody = new CANNON.Body({ mass: 5, material: this.defaultContactMaterial })
        this.sphereBody.addShape(this.sphereShape)
        this.sphereBody.position.set(0, 6, 0)
        this.sphereBody.linearDamping = 0.9
        this.world.addBody(this.sphereBody)

        // gltf to cannon
        this.result; // shape
        this.resultBody;

        //gui 
        this.gui;



        //HTML
        this.canvas = document.querySelector('canvas.webgl')
        this.htmlLoader = document.querySelector('.loader')
        this.htmlLoaderText = document.querySelector('.loader p')
        this.sources = document.querySelectorAll('picture source')
        this.mainImg = document.querySelector('picture img')
        this.htmlLoaderText = document.querySelector('.txt-loader')
        this.picture = document.querySelector('picture')
        this.percentageBar = document.querySelector('.bar-container')
        this.explore = document.querySelector('.explore')
        this.transition = document.querySelector('.transition')
        this.appNoise = document.querySelector('#appNoise')
        this.nav = document.querySelector('nav')
        this.footer = document.querySelector('footer')
        this.mainText = document.querySelector('.main-text')
        this.htmlLoaderText2 = document.querySelector('.txt-loader-loading')

        this.mail = document.querySelector('.mail p')
        this.mailBtn = document.querySelector('.mail button')
        this.gameZone = document.querySelector('.gameZone')

        //THREE.JS
        this.scene = new Scene();
        this.textureLoader = new TextureLoader()
        this.clock = new Clock()
        this.controls;


        // RENDER 
        this.renderer = new WebGLRenderer({ antialias: true, canvas: this.canvas });
        this.renderer.outputEncoding = sRGBEncoding
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.toneMapping = ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 0.7;

        this.RenderTargetClass = null;

        if (this.renderer.getPixelRatio() < 1 || this.renderer.getPixelRatio() === 1 && this.renderer.capabilities.isWebGL2) {
            this.RenderTargetClass = WebGLMultisampleRenderTarget
            //  console.log('Using WebGLMultisampleRenderTarget')
        }
        else {
            this.RenderTargetClass = WebGLRenderTarget
            //  console.log('Using WebGLRenderTarget')
        }

        // Render Target
        this.renderTarget = new this.RenderTargetClass(
            800,
            600,
            {
                minFilter: LinearFilter,
                magFilter: LinearFilter,
                format: RGBAFormat,
                encoding: sRGBEncoding
            }
        )


        this.camera = new PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, 10000)
        this.scene.add(this.camera)


        // LOADING IMGS
        this.arrayImgs = [
            { source: 'assets/2.jpg', sourceWebp: 'assets/2.webp', sourceAvif: 'assets/2.avif', text: 'Engine starting sequence initiated ...' },
            { source: 'assets/3.jpg', sourceWebp: 'assets/3.webp', sourceAvif: 'assets/3.avif', text: 'Weapon system operational ...' },
            { source: 'assets/5.jpg', sourceWebp: 'assets/5.webp', sourceAvif: 'assets/5.avif', text: 'Ready for take-off!' }
        ]

        this.stepLoad = 0
        this.meshToLoad;

        //TEXTURES 


        // GSAP
        this.tl = gsap.timeline();
        this.oldElapsedTime = 0;

        //MATERIALS
        this.bakedMaterial = new MeshBasicMaterial()
        this.blueMaterial = new MeshBasicMaterial({ color: 0x00060f })
        this.whiteMaterial = new MeshBasicMaterial({ color: 0x969696 })

        //sun
        this.effectController = {
            turbidity: 10,
            rayleigh: 3,
            mieCoefficient: 0.005,
            mieDirectionalG: 0.7,
            elevation: 2,
            azimuth: 180,
            exposure: this.renderer.toneMappingExposure
        };


        this.nextBtn = document.querySelector(".next-btn")
        this.tutoEnded = false;


        const initWorld = () => {
            // e.preventDefault();

            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(this.animation.transition(this.mobileMode));
                })
            }).then(() => {

                if (this.mobileMode === true) {
                    document.body.style.minHeight = '100vh';
                    document.body.style.overflow = 'hidden';
                    document.body.style.position = 'fixed';
                    this.numClick = 0;
                    document.querySelector('.tutoWrapper').remove()
                    mobileTutorial()
                    
                } else {
                    this.tutoEnded = true
                }

                this.initPointerLock(this.mobileMode);
                // this.createTutorial(this.mobileMode);
                this.initSky();
                this.resize()
                this.gameZone.style.visibility = 'visible';

                this.explore.removeEventListener('click', initWorld, { passive: true })
                this.bigRay = new RaycasterHover(this.camera, this.scene)
                this.events();
                // cannonDebugger(this.scene, this.world.bodies)

                if (this.workerLoader) {
                    this.workerLoader.terminate()
                }

                this.animate()

            })
        }

        const mobileTutorial = () => {

            this.nextBtn.addEventListener('click', handlerTutorial);
            

        }

        const handlerTutorial = () => {

            this.tutoMob = document.querySelector('.mobileTuto')
            this.menuTuto = document.querySelector('.menu-popin-wrapper')
            this.zone1 = document.querySelector('.zone-1')


            //STEP 1
            this.joystickWrapper = document.querySelector('.joystick-popin-wrapper')

            //STEP 2
            this.lookAroundWrapper = document.querySelector('.look-around-popin')
            this.hand = document.querySelector('.hand')
            this.circle = document.querySelector('.circle')

            //STEP 3
            this.cursorPopinWrapper = document.querySelector('.cursor-popin-wrapper')
            this.handCursor = document.querySelector('.hand-cursor')
            this.crosshairDom = document.querySelector('.crosshair')

            //STEP 4
            this.menuTuto = document.querySelector('.menu-popin-wrapper')

            this.numClick += 1

            if (this.numClick <= 3) {


                if (this.numClick === 1) {
                    this.joystickWrapper.classList.add('hide')
                    this.lookAroundWrapper.classList.add('show')
                    this.zone1.classList.add('hide')
                    this.crosshairDom.classList.add('hide')
                    this.nextBtn.innerHTML = "All clear"

                } else if (this.numClick === 2) {

                    this.crosshairDom.classList.remove('hide')
                    this.crosshairDom.classList.add('hit-anim')
                    this.lookAroundWrapper.classList.remove('show')
                    this.cursorPopinWrapper.classList.add('show')
                    this.handCursor.classList.add('show')
                    this.nextBtn.innerHTML = "Ok!"


                } else if (this.numClick === 3) {

                    this.crosshairDom.classList.remove('hit-anim')
                    this.cursorPopinWrapper.classList.remove('show')
                    this.menuTuto.classList.add('show')
                    this.menuKey.classList.add('show')

                    this.nextBtn.innerHTML = "Got it!"
                    this.handCursor.classList.remove('show')
                }

            } else {
                this.zone1.classList.remove('hide')
                this.tutoMob.style.visibility = "hidden"
                this.nextBtn.classList.add('hide')
                this.menuTuto.classList.remove('show')

                this.nextBtn.style.opacity = 0
                this.nextBtn.style.visibility = 'hidden'
                this.nextBtn.removeEventListener('click', handlerTutorial);
                this.tutoEnded = true

                document.addEventListener('click', () => {
                    this.bigRay.rayCaster(this.animation, this.controls, this.mobileMode);
                });

                this.eventsMenu()

            }
        }


        this.explore.addEventListener('click', initWorld, { passive: true });

        window.addEventListener("resize", () => {
            this.resize()
        });

        

    }

    

    eventsMenu() {
        this.menuKeyClose.addEventListener('click', (e) => {
            this.animation.menuClose(this.controls, this.mobileMode, this.bigRay.raycasterEl)
        })

        this.menuKeyClose2.addEventListener('click', (e) => {
            this.animation.menuClose(this.controls, this.mobileMode, this.bigRay.raycasterEl)
        })


        this.menuKey.addEventListener('click', () => {
            this.animation.menuOpen(this.controls, this.mobileMode);
        })


        this.mailBtn.addEventListener('click', (e) => {
            this.clickToCopy()
        })

    }



    events() {



        document.addEventListener('keydown', (event) => {
            if (event.code == 'KeyP' || event.code == 'Semicolon') {
                this.animation.menuOpen(this.controls, this.mobileMode);
            }
        });


        if (this.mobileMode === false) {
            document.addEventListener('click', () => {
                this.bigRay.rayCaster(this.animation, this.controls, this.mobileMode);
            });

            this.eventsMenu()
        }



        if (!this.mobileMode) {
            this.gameZone.addEventListener('click', (e) => {

                return new Promise((resolve) => {

                    setTimeout(() => {
                        resolve(this.controls.lock());
                    }, 400)
                })
            })
        } else {
            this.gameZone.remove()
        }
    }

    clickToCopy() {
        navigator.clipboard.writeText(this.mail.innerHTML);
        this.animation.copyMail()
    }

    homepageTrigger() {

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(this.animation.homepage());
            }, 2000)
        })
    }

    instantiateWorker() {


        if ((navigator.vendor.match(/apple/i) || "").length > 0) {

            this.loader;
            this.dracoLoader;


            (async () => {
                const { DRACOLoader } = await import(
                    "three/examples/jsm/loaders/DRACOLoader.js"
                );
                this.dracoLoader = new DRACOLoader();
                this.dracoLoader.setDecoderPath("draco/");

                const { GLTFLoader } = await import(
                    "three/examples/jsm/loaders/GLTFLoader.js"
                );
                this.loader = new GLTFLoader();
                this.loader.setDRACOLoader(this.dracoLoader);

                this.loader.load("models/light-hangar26.glb", (gltf) => {
                    gltf.scene.children.forEach((obj) => {
                        if (obj.type === "Mesh") {
                            if (
                                obj.name === "mur-back" ||
                                obj.name === "avion1" ||
                                obj.name === "chev1" ||
                                obj.name === "chev5" ||
                                obj.name === "chev6" ||
                                obj.name === "chev3" ||
                                obj.name === "chev4" ||
                                obj.name === "chev2" ||
                                obj.name === "mur-face" ||
                                obj.name === "mur-right" ||
                                obj.name === "mur-left" ||
                                obj.name === "desk001"
                            ) {
                                this.result = threeToCannon(obj, { type: ShapeType.CUBE });
                                this.resultBody = new CANNON.Body({
                                    mass: 0,
                                    material: this.defaultContactMaterial,
                                });
                                this.resultBody.addShape(
                                    this.result.shape,
                                    this.result.offset
                                );
                                //   this.resultBody.addEventListener("collide", () => {
                                //     console.log(`collided`);
                                //   });
                                this.world.addBody(this.resultBody);
                            }
                        }
                    });

                    this.scene.add(gltf.scene);

                    for (
                        var i = this.scene.children[1].children.length - 1;
                        i >= 0;
                        i--
                    ) {
                        if (
                            this.scene.children[1].children[i].name === "mur-back" ||
                            this.scene.children[1].children[i].name === "avion1" ||
                            this.scene.children[1].children[i].name === "chev1" ||
                            this.scene.children[1].children[i].name === "chev5" ||
                            this.scene.children[1].children[i].name === "chev6" ||
                            this.scene.children[1].children[i].name === "chev3" ||
                            this.scene.children[1].children[i].name === "chev4" ||
                            this.scene.children[1].children[i].name === "chev2" ||
                            this.scene.children[1].children[i].name === "mur-face" ||
                            this.scene.children[1].children[i].name === "mur-right" ||
                            this.scene.children[1].children[i].name === "mur-left" ||
                            this.scene.children[1].children[i].name === "desk001"
                        ) {
                            this.scene.children[1].remove(
                                this.scene.children[1].children[i]
                            );
                        }
                    }
                });

                this.p1 = this.loading(0, "26%", "0. GEOMETRIE bien construite").then(
                    () => {
                        return new Promise((resolve) => {
                            setTimeout(() => {
                                resolve(this.createTexture());
                            }, 2000);
                        });
                    }
                );
            })().catch((error) => {
                // Handle/report error
                console.error(error);
            });
        } else {

            this.workerLoader = new Worker(new URL('./workerLoader.js', import.meta.url));
            this.workerLoader.postMessage('models/light-hangar26.glb');
            this.waitingWorker(this.workerLoader);
        }

    }


    mobileDetect() {
        this.isMobile = /mobi/i.test(navigator.userAgent);

        if (this.isMobile) {
            this.mobileMode = true
        } else {
            this.mobileMode = false
        }
    }

    resize() {

        // Update sizes
        this.sizes.width = window.innerWidth
        this.sizes.height = window.innerHeight

        // Update camera
        this.camera.aspect = this.sizes.width / this.sizes.height
        this.camera.updateProjectionMatrix()

        // Update renderer
        this.renderer.setSize(this.sizes.width, this.sizes.height)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    }

    initSky() {
        const sky = new Sky();
        sky.scale.setScalar(450000);
        this.scene.add(sky);
        const sun = new Vector3();
        this.guiChanged(sky, sun)

    }

    guiChanged(sky, sun) {

        const uniforms = sky.material.uniforms;
        uniforms['turbidity'].value = this.effectController.turbidity;
        uniforms['rayleigh'].value = this.effectController.rayleigh;
        uniforms['mieCoefficient'].value = this.effectController.mieCoefficient;
        uniforms['mieDirectionalG'].value = this.effectController.mieDirectionalG;

        const phi = MathUtils.degToRad(90 - this.effectController.elevation);
        const theta = MathUtils.degToRad(this.effectController.azimuth);

        sun.setFromSphericalCoords(1, phi, theta);

        uniforms['sunPosition'].value.copy(sun);

        this.renderer.toneMappingExposure = this.effectController.exposure;
        this.renderer.render(this.scene, this.camera);

    }

    animate() {

        // const deltaTime = Math.min( 0.1, this.clock.getDelta() );
        // const elapsedTime = this.clock.getElapsedTime()

        requestAnimationFrame(this.animate.bind(this));

        const time = performance.now() / 1000
        const dt = time - this.lastCallTime
        this.lastCallTime = time


        if (this.controls.enabled) {
            this.world.step(this.timeStep, dt)

            if (this.tutoEnded === true) {
                this.bigRay.rayCasterHover();
            }
        }

        this.controls.update(dt, this.mobileMode)
        this.renderer.render(this.scene, this.camera);


    }

    initPointerLock(mobileMode) {
        this.controls = new PointerLockControlsCannon(this.camera, this.sphereBody, mobileMode)
        this.scene.add(this.controls.getObject())

        if (mobileMode === true) {

            this.controls.enabled = true

        } else {
            this.controls.lock()

            this.controls.addEventListener('lock', () => {
                this.controls.enabled = true
            })

            this.controls.addEventListener('unlock', () => {
                this.controls.enabled = false
            })
        }


    }


    changeLoading(step, percent) {


        this.tl = gsap.timeline();
        this.tl.to(this.htmlLoaderText, { opacity: 0, duration: 0.2, ease: "power2.out" }, 0);

        if (percent === '26%') {

            this.tl.to('.pic-init', { opacity: 0, duration: 0.1, ease: "power2.out" }, 0.9);
            this.tl.to('.pic-two', { opacity: 1, duration: 0.5, ease: "power2.in" }, 0.3);

        } else if (percent === '66%') {

            // this.tl.to(['.pic-init','.pic-two'], {opacity:0, duration: 0.1, ease: "power2.in"}, 0.4);
            this.tl.to('.pic-two', { opacity: 0, duration: 0.1, ease: "power2.out" }, 0.9);
            this.tl.to('.pic-three', { opacity: 1, duration: 0.5, ease: "power2.in" }, 0.3);

        } else if (percent === '100%') {
            this.tl.to('.pic-three', { opacity: 0, duration: 0.1, ease: "power2.out" }, 0.9);
            this.tl.to('.pic-last', { opacity: 1, duration: 0.5, ease: "power2.in" }, 0.3);

        }


        this.htmlLoaderText.innerHTML = this.arrayImgs[step].text;

        this.tl.to(this.htmlLoaderText, { opacity: 1, duration: 0.8, ease: "power2.inOut" }, 0);
        this.tl.to(this.percentageBar, { width: percent, duration: 0.8, ease: "power2.out" }, 0.3);

        document.querySelector('.percentage div').innerHTML = percent


        if (step === 2) {
            // document.querySelector('.pic-two').remove()
            // document.querySelector('.pic-three').remove()

            this.tl.to(this.htmlLoaderText2, { innerHTML: 'Loading complete', duration: 0.2, ease: "power2.inOut" }, 0);
            this.tl.to(this.explore, { zIndex: 5, duration: 0.1 }, 1);

            this.tl.to(".explore-compo", {
                visibility: 'visible',
                opacity: 1,
                stagger: 0.2
            });
            // this.tl.to(['.pic-init','.pic-two','.pic-three'], {opacity: 0, duration: 0.1},0.9)
            this.tl.to(this.percentageBar, { opacity: 0, duration: 1 }, 1);
            this.tl.to('.pic-last', { opacity: 0.5, duration: 0.4 }, 1);

        }
    }

    createScene(textures) {
        // console.log("ok now let's put everything into the scene..")


        this.scene.traverse((obj) => {
            // console.log(obj.children)

            // obj.material = new MeshNormalMaterial()



            if (obj.name === 'baked' || obj.name === 'arceau003' || obj.name === 'door2' || obj.name === 'door1' || obj.name === 'arceau004' || obj.name === 'arceau002' || obj.name === 'arceau007' || obj.name === 'arceau004' || obj.name === 'arceau005' || obj.name === 'arceau006' || obj.name === 'presentation' || obj.name === 'floor' || obj.name === 'Extrude_6002_7') {
                const bakedMesh = obj;
                bakedMesh.material = this.bakedMaterial;
                bakedMesh.material.map = textures[7]
            }

            if (obj.name === 'desk' || obj.name === 'su57') {
                const deskMesh = obj;
                deskMesh.material = this.whiteMaterial
            }

            // todo : merge toutes les front-face des tableaux dans une seule texture
            if (obj.name === 'expo-uno_2') {

                const mesh = obj;
                // /mesh2.material.side =  BackSide;
                mesh.material = new MeshBasicMaterial();
                mesh.material.map = textures[0]
            }

            if (obj.name === 'expo-two_2') {

                const mesh3 = obj;
                mesh3.material = new MeshBasicMaterial();
                mesh3.material.map = textures[2]

            }

            if (obj.name === 'expo-three_2') {

                const mesh2 = obj;
                mesh2.material = new MeshBasicMaterial();
                mesh2.material.map = textures[1]
            }


            if (obj.name === 'expo-four_2') {

                const mesh4 = obj;
                mesh4.material = new MeshBasicMaterial();
                mesh4.material.map = textures[3]

            }





            if (obj.name === 'bando_1') {
                const mesh8 = obj;
                mesh8.material = new MeshBasicMaterial();
                mesh8.material.map = textures[2]
            }


            if (obj.name === 'expo-five_2') {

                const mesh5 = obj;
                mesh5.material = new MeshBasicMaterial();
                mesh5.material.map = textures[4]
            }

            if (obj.name === 'expo-six_2') {

                const mesh4 = obj;
                mesh4.material = new MeshBasicMaterial();
                mesh4.material.map = textures[5]

            }

            // if(obj.name === 'Plane023_1')  {
            //     const mesh7 = obj;
            //     mesh7.material = new MeshBasicMaterial();
            //     mesh7.material.map = textures[4]          
            // }

            if (obj.name === 'plaquette-all_1') {
                const mesh8 = obj;
                mesh8.material = new MeshBasicMaterial();
                mesh8.material.map = textures[4]
            }


            if (obj.name === 'bio') {
                const mesh6 = obj;
                mesh6.material = new MeshBasicMaterial({ transparent: true });
                mesh6.material.map = textures[6]
            }



            // const bakedMesh = obj.children.find(child => child.name === 'baked')

        })

        this.p3 = this.loading(2, '100%', '2. TEXTURES & materiaux appliquées')

    }

    createTexture() {

        this.textures = []
        // console.log('ok now the textures..')


        this.expoUno = this.textureLoader.load('assets/uno-min.jpg')
        this.expoUno.flipY = false
        this.expoUno.encoding = sRGBEncoding

        this.expoTwo = this.textureLoader.load('assets/two-min.jpg')
        this.expoTwo.flipY = false
        this.expoTwo.encoding = sRGBEncoding

        this.expoThree = this.textureLoader.load('assets/three-min.jpg')
        this.expoThree.flipY = false
        this.expoThree.encoding = sRGBEncoding

        this.expoFour = this.textureLoader.load('assets/four-min.jpg')
        this.expoFour.flipY = false
        this.expoFour.encoding = sRGBEncoding

        this.expoFive = this.textureLoader.load('assets/five-min.jpg')
        this.expoFive.flipY = false
        this.expoFive.encoding = sRGBEncoding

        this.expoSix = this.textureLoader.load('assets/six-min.jpg')
        this.expoSix.flipY = false
        this.expoSix.encoding = sRGBEncoding

        this.bio = this.textureLoader.load('assets/bio.png')
        this.bio.flipY = false
        this.bio.encoding = sRGBEncoding

        this.bakedTexture = this.textureLoader.load('assets/baked-three.jpg')
        this.bakedTexture.flipY = false
        this.bakedTexture.encoding = sRGBEncoding

        this.textures.push(this.expoUno, this.expoTwo, this.expoThree, this.expoFour, this.expoFive, this.expoSix, this.bio, this.bakedTexture)


        this.p2 = this.loading(1, '66%', '1. TEXTURES bien chargées').then(() => {
            return new Promise((resolve) => {

                setTimeout(() => {
                    resolve(this.createScene(this.textures));
                }, 2000)
            })
        })


    }



    waitingWorker(workerLoader) {


        workerLoader.onmessage = e => {



            if (typeof e.data === 'number') {

                this.meshToLoad = e.data;


            } else {

                // todo : merge dans blender 
                if (e.data.transfer_list.name === 'contact-btn001' || e.data.transfer_list.name === 'expo-uno_1' || e.data.transfer_list.name === 'expo-two_1' || e.data.transfer_list.name === 'expo-three_1' || e.data.transfer_list.name === 'expo-four_1' || e.data.transfer_list.name === 'expo-five_1' || e.data.transfer_list.name === 'plaquette001') {

                    this.material = this.blueMaterial

                }

                if (e.data.transfer_list.name === 'bando' || e.data.transfer_list.name === 'desk' || e.data.transfer_list.name === 'lamp_1' || e.data.transfer_list.name === 'su57' || e.data.transfer_list.name === 'contact_me001') {
                    this.material = this.whiteMaterial;
                }

                this.geometry = new BufferGeometry();
                this.geometry.setAttribute('position', new BufferAttribute(new Float32Array(e.data.transfer_list.position), 3)) // vertices
                this.geometry.setAttribute('uv', new BufferAttribute(new Float32Array(e.data.transfer_list.uv), 2))

                if (e.data.transfer_list.normal) {
                    this.geometry.setAttribute('normal', new BufferAttribute(new Float32Array(e.data.transfer_list.normal.array), 3))
                }

                const index = new BufferAttribute(new Uint16Array(e.data.transfer_list.index.array), 1)


                this.geometry.setIndex(index)
                this.geometry.index.needsUpdate = true;
                this.geometry.computeBoundingBox(e.data.transfer_list.boundingBoxMax, e.data.transfer_list.boundingBoxMin)
                this.geometry.computeBoundingSphere(e.data.transfer_list.boundingSphereCenter, e.data.transfer_list.boundingSphereRadius)
                this.geometry.computeVertexNormals();

                // todo : 1 material for cadres

                this.loaded_object = new Mesh(this.geometry, this.material);
                this.loaded_object.name = e.data.transfer_list.name;
                this.loaded_object.position.x = e.data.transfer_list.mainPosition.x
                this.loaded_object.position.y = e.data.transfer_list.mainPosition.y
                this.loaded_object.position.z = e.data.transfer_list.mainPosition.z

                this.loaded_object.quaternion.x = e.data.transfer_list.quaternionX
                this.loaded_object.quaternion.y = e.data.transfer_list.quaternionY
                this.loaded_object.quaternion.z = e.data.transfer_list.quaternionZ
                this.loaded_object.quaternion.w = e.data.transfer_list.quaternionW

                this.loaded_object.rotation.x = e.data.transfer_list.rotationX
                this.loaded_object.rotation.y = e.data.transfer_list.rotationY
                this.loaded_object.rotation.z = e.data.transfer_list.rotationZ
                this.loaded_object.order = 'XYZ'


                if (this.loaded_object.name === 'mur-back' || e.data.transfer_list.name === 'avion1' || e.data.transfer_list.name === 'chev1' || e.data.transfer_list.name === 'chev6' || e.data.transfer_list.name === 'chev5' || e.data.transfer_list.name === 'chev3' || e.data.transfer_list.name === 'chev4' || e.data.transfer_list.name === 'chev2' || this.loaded_object.name === 'mur-face' || this.loaded_object.name === 'mur-right' || this.loaded_object.name === 'mur-left' || this.loaded_object.name === 'desk001') {

                    this.result = threeToCannon(this.loaded_object, { type: ShapeType.CUBE });
                    this.resultBody = new CANNON.Body({ mass: 0, material: this.defaultContactMaterial })
                    this.resultBody.addShape(this.result.shape, this.result.offset)
                    // this.resultBody.addEventListener('collide', ()=> {console.log(`collided ${this.loaded_object.name}`)})

                    this.world.addBody(this.resultBody)

                } else {
                    this.scene.add(this.loaded_object)
                }

                this.stepLoad += 1;

                if (this.stepLoad === this.meshToLoad) {
                    this.p1 = this.loading(0, '26%', '0. GEOMETRIE bien construite')
                        .then(() => {
                            return new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve(this.createTexture());
                                }, 2000)
                            })
                        })
                }

            }



        }
    }



    loading(step, percentage, message) {
        return new Promise(resolve => {

            // console.log(message)

            setTimeout(() => {

                resolve(this.changeLoading(step, percentage))

            }, 2000);

        });
    }

}