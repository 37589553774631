import gsap from 'gsap/all';

export class Animation {
    constructor(menuKeyClose) {

        this.headline = document.querySelectorAll('.main-text h1 span')
        this.subtitle = document.querySelectorAll('.main-text h2')
        this.txtLoader = document.querySelector('.loader-txt-wrapper')
        this.overlay = document.querySelector('.overlay')
        this.bar = document.querySelector('.bar-container')

        this.explore = document.querySelector('.explore')
        this.transitionContainer = document.querySelector('.transitionContainer')
        this.transitionDom = document.querySelector('.transition')
        this.airplane = document.querySelector('.airplane')

        this.menuContainer = document.querySelector('.menu-container')
        this.menuKey = document.querySelector('.menu-key')
        this.menuKeyClose = menuKeyClose
        this.gameZone =  document.querySelector('.gameZone')
        

        // TODO
        this.joysticks = document.querySelector('.mobileControls')
        this.tutorial = document.querySelector('.tutoWrapper')
      

        this.TL;

        
    }

    fullScreenMob() {
        this.doc = window.document;
        this.docEl = this.doc.documentElement;

        this.requestFullScreen = this.docEl.requestFullscreen || this.docEl.mozRequestFullScreen || this.docEl.webkitRequestFullScreen || this.docEl.msRequestFullscreen;
        this.cancelFullScreen = this.doc.exitFullscreen || this.doc.mozCancelFullScreen || this.doc.webkitExitFullscreen || this.doc.msExitFullscreen;

        if (!this.doc.fullscreenElement && !this.doc.mozFullScreenElement && !this.doc.webkitFullscreenElement && !this.doc.msFullscreenElement) {
            this.requestFullScreen.call(this.docEl);
            window.screen.orientation.lock('landscape')
        }
        else {
            this.cancelFullScreen.call(this.doc);
            window.screen.orientation.lock('portrait')
        }

    }

    homepage() {
        this.TL = gsap.timeline({paused: true});
        this.TL.to(this.headline, { stagger:0.4, opacity:0.8, ease:"power3.out" }, 0.3);
        this.TL.to(this.subtitle, { stagger: 0.3, opacity:0.8, ease:"power3.out"}, 1.3);
        this.TL.to(this.overlay, {width:0, duration: 2, ease: "power4.out"}, 2)
        this.TL.to([this.txtLoader, this.bar], { opacity:0.8, ease:"power3.out"}, 3.3);
        this.TL.to('footer', { opacity:1, ease:"power3.out"}, 3.5);
        this.TL.play();
    }

    transition(mobileMode) {
        this.TL = gsap.timeline({paused: true});
        document.body.style.overflow = 'hidden';
        document.querySelector("html").style.overflow = 'hidden';
        this.transitionDom.style.visibility = 'visible';
       
        this.TL.to(['.home', 'footer', 'nav'], { duration: 0.3, opacity:0, ease: "power3.in"}, 0)
        this.TL.to(['.home', 'footer', 'nav'], { display: 'none'}, 0.3)
        this.TL.to(this.transitionContainer, { duration: 0.4, opacity: 1, visibility:'visible'}, 0.2)

        if(mobileMode) {
            this.TL.to(this.transitionDom, { duration: 1, "clipPath": "polygon(30% 0, 70% 0, 100% 100%, 0% 100%)"}, 0.5)
            this.TL.to(this.airplane, {duration:4, bottom: '130%', ease: 'power3.out'}, 0.47)
            this.TL.to(this.transitionDom, { duration: 1, "clipPath": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)"}, 1.4)
        } else {
            this.TL.to(this.transitionDom, { duration: 1, "clipPath": "polygon(100% 50%, 100% 50%, 0% 100%, 0 0)"}, 0.5)
            this.TL.to(this.airplane, {duration:3, left: '120%', ease: 'power3.out'}, 0.47)
            this.TL.to(this.transitionDom, { duration: 1, "clipPath": "polygon(100% 0%, 100% 100%, 0% 100%, 0 0)"}, 1.4)
        
        }

        this.TL.to('canvas', {duration: 0.4, display:'block', opacity: 1}, 2.4)
        this.TL.to([this.transitionContainer, this.transitionDom], {duration: 0.4, opacity: 0, visibility: 'hidden'}, 2.4)

        if(mobileMode === true) {
            this.TL.to([this.joysticks, '.mobileTuto', '.next-btn'], {duration: 0.7, opacity: 1, visibility: 'visible', ease:'power3.out'}, 3)
            document.querySelector('#appNoise').style.pointerEvents = 'auto'
            this.TL.add(() => {this.fullScreenMob()}, 3)
           
        } else {
            this.TL.to([this.menuKey, this.tutorial], {duration: 0.7, opacity: 1, visibility: 'visible', ease:'power3.out'}, 3)
            
        }
       
        this.TL.add(()=> {
            document.querySelector(".home").remove()
            document.querySelector("footer").remove()
            document.querySelector(".transitionContainer").remove()
            
            if(mobileMode === false) {
                document.querySelector(".mobileTuto").remove()
            }
           
        }, 3.7)

        this.TL.play();
    }

    copyMail() {
        this.TL = gsap.timeline({paused: true});
        this.TL.to('.mail img', {duration: 0.2, opacity: 0, ease: "power2.out"}, 0)
        this.TL.add(()=> {
            document.querySelector('.mail img').setAttribute('src', 'assets/check.svg')
        }, 0.2)
        this.TL.to('.mail img', {duration: 0.2, opacity: 1, ease: "power2.out"}, 0.3)
        this.TL.to('.isCopied', {innerHTML: 'Email copied', ease: "power2.out"}, 0.3)
        this.TL.play();
    }

    menuOpen(controls, mobileMode) {

        
        this.gameZone.style.visibility = 'hidden';
        this.TL = gsap.timeline({paused: true});

        if(mobileMode === true) {
            this.TL.to([this.joysticks, '.btn-next'], {duration: 0.2, ease: "power3.out", opacity: 0, visibility:'hidden'}, 0)
            document.querySelector('#appNoise').style.pointerEvents = 'none'
        }

        this.TL.to(this.menuKey, {duration: 0.5, ease: "power3.out", opacity: 0, visibility:'hidden'}, 0)
        this.TL.to(this.menuContainer, { duration: 1.2, visibility: 'visible', "clipPath": "circle(143.2% at 100% 0)"}, 0)
        this.TL.to(this.menuKeyClose, {opacity: 1 }, 1.3)
        
        this.TL.add(()=> { controls.unlock() }, 1.3)
        this.TL.play();
    }


    menuClose(controls, mobileMode, raycaster) {

        this.gameZone.style.visibility = 'visible';
        
        this.TL = gsap.timeline({paused: true});
        this.TL.add(()=> { controls.lock() }, 0)
        this.TL.to(this.menuContainer, { duration: 1.2, "clipPath": "circle(0% at 100% 0)"}, 0)
        this.TL.to(this.menuKeyClose, {opacity: 0 }, 1.3)
        this.TL.to(this.menuContainer, { visibility: 'hidden'}, 1.3)
        this.TL.to(this.menuKey, {duration: 0.5, ease: "power3.out", opacity: 1, visibility:'visible'}, 1.5)
        this.TL.add(()=> { raycaster.far = Infinity}, 2);

        
        if(mobileMode === true) {
            document.querySelector('#appNoise').style.pointerEvents = 'auto'
            this.TL.to(this.joysticks, {duration: 0.5, ease: "power3.out", opacity: 1, visibility:'visible'}, 1.5)
        }
        this.TL.play();
    }

    animStartLoading(one, two, picture, htmlLoaderText, percentageBar, percent) {
        if(one) {
            this.TL.to(picture, {opacity:'0%', duration: 0.5, ease: "power2.inOut"}, 0);
            this.TL.to(htmlLoaderText, {opacity:'0%', duration: 0.5, ease: "power2.inOut"}, 0);
        }

        if(two) {
            this.TL.to( picture, {opacity:'100%', duration: 0.5, ease: "power2.inOut"}, 0);
            this.TL.to( htmlLoaderText, {opacity:'100%', duration: 0.5, ease: "power2.inOut"}, 0);
            this.TL.to( percentageBar, {width: percent, duration: 0.5, ease: "power2.inOut"}, 0.2);
        }

    }

    endLoading(htmlLoaderText2, explore, percentageBar, picture) {
        this.TL.to( htmlLoaderText2, {innerHTML:'Loading complete', duration: 0.2, ease: "power2.inOut"}, 0);
        this.TL.to( explore, {opacity:1, visibility: 'visible', duration: 0.4 }, 1);
        this.TL.to( percentageBar, {opacity: 0, duration: 1 }, 1);
        this.TL.to( picture, {opacity: 0.5, duration: 0.4}, 1);
    }

   
}