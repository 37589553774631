import { EventDispatcher, Quaternion, Object3D, Vector3, Euler} from 'three/build/three.module.js'
import * as CANNON from 'cannon-es'
import nipplejs from 'nipplejs'


class PointerLockControlsCannon extends EventDispatcher {
  constructor(camera, cannonBody, mobileMode) {
    super()

    // const workerPhysics = new Worker(new URL('./workerPhysics.js', import.meta.url));
    // this.enabled = false

   
    this.ww = document.body.clientWidth/2;
	  this.wh = document.body.clientHeight/.2;
	  this.h = window.innerHeight;
    this.clientX, this.clientY;
		this.xfromtouch = 0;
		this.yfromtouch = 0;


    this.array_x = []
    this.array_y = []
    this.touches_x, this.touches_y;
    this.lastxpos = 0;
    this.lastypos = 0;

    this.eulerX_angle = [].map(Number)
    this.eulerY_angle = [].map(Number)
    this.eulerY, this.eulerX;
    this.eulerY_final = 0;
    this.eulerX_final = 0;
    this.eulerY_total, this.eulerX_total;

    this.changeEvent = {
      type: 'change'
    };



    this.noise = document.querySelector('#appNoise')
    this.zone1 = document.querySelector('.zone-1')

    // this.noise.addEventListener('touchcancel', (event) => {
    //   this.disconnectMobile()
    //   this.direction.destroy()
    //   this.reconnectMob()
    //   this.createMobileControls()
    // });



    this.cannonBody = cannonBody

    // var eyeYPos = 2 // eyes are 2 meters above the ground
    this.velocityFactor = 0.25
    this.jumpVelocity = 10

    this.pitchObject = new Object3D()
    this.pitchObject.add(camera)
    this.pitchObject.position.y = 2.5


    this.yawObject = new Object3D()
    this.yawObject.position.y = 2
    this.yawObject.add(this.pitchObject)

    this.quaternion = new Quaternion()

    this.moveForward = false
    this.moveBackward = false
    this.moveLeft = false
    this.moveRight = false

    this.canJump = false

    const contactNormal = new CANNON.Vec3() // Normal in the contact, pointing *out* of whatever the player touched
    const upAxis = new CANNON.Vec3(0, 1, 0)
    this.cannonBody.addEventListener('collide', (event) => {
      const { contact } = event

      // contact.bi and contact.bj are the colliding bodies, and contact.ni is the collision normal.
      // We do not yet know which one is which! Let's check.
      if (contact.bi.id === this.cannonBody.id) {
        // bi is the player body, flip the contact normal
        contact.ni.negate(contactNormal)
      } else {
        // bi is something else. Keep the normal as it is
        contactNormal.copy(contact.ni)
      }

      // If contactNormal.dot(upAxis) is between 0 and 1, we know that the contact normal is somewhat in the up direction.
      if (contactNormal.dot(upAxis) > 0.5) {
        // Use a "good" threshold value between 0 and 1 here!
        this.canJump = true
      }
    })

    // this.movementsObj = {
    //  x: 0,
    //  y: 0,
    // }

    this.velocity = this.cannonBody.velocity

    // Moves the camera to the cannon.js object position and adds velocity to the object if the run key is down
    this.inputVelocity = new Vector3()
    this.euler = new Euler()

    this.lockEvent = { type: 'lock' }
    this.unlockEvent = { type: 'unlock' }

    

    if(mobileMode === true) {
      this.direction;
      this.orientation;
      this.connectMobile()
      
    } else {
      this.mouse_delta = {x: 0, y: 0};
      this.connect()
    }

  }

  lerp(v0, v1, t) {
    return (1 - t) * v0 + t * v1;
  }


  connectMobile() {
    this.createMobileControls();
    // this.onOrientationTouchMove();
    this.onDirectionTouchMove();
    this.noise.addEventListener('touchmove', this.arrayTouches);
    this.noise.addEventListener('touchmove', this.onTouchMove);
    this.noise.addEventListener('touchend', this.onTouchEnd);
    this.noise.addEventListener('touchmove', this.onTouch)
    // this.noise.addEventListener('pointerlockchange', this.onPointerlockChange)

  }

  reconnectMob() {
    this.noise.addEventListener('touchmove', this.arrayTouches);
    this.noise.addEventListener('touchmove', this.onTouchMove);
    this.noise.addEventListener('touchend', this.onTouchEnd);
    this.noise.addEventListener('touchmove', this.onTouch)
  }

  disconnectMobile(){
    this.noise.removeEventListener('touchmove', this.onTouchMove);
    this.noise.removeEventListener('touchmove', this.arrayTouches);
    this.noise.removeEventListener('touchend', this.onTouchEnd);
    this.noise.removeEventListener('touchmove', this.onTouch)
  }

  createMobileControls() {

   this.zone1.innerHTML = ''


    this.direction = nipplejs.create({
      zone: document.querySelector(".zone-1"),
      mode: 'static',
      position: {left: '5rem', bottom: '5rem'},
      size:70,
      maxNumberOfNipples: 1,
      restJoystick: true,
      dynamicPage: true,
      
    });
  }


  connect() {
    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('pointerlockchange', this.onPointerlockChange)
    document.addEventListener('pointerlockerror', this.onPointerlockError)
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keyup', this.onKeyUp)
  }

  disconnect() {
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('pointerlockchange', this.onPointerlockChange)
    document.removeEventListener('pointerlockerror', this.onPointerlockError)
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keyup', this.onKeyUp)
    
  }



  dispose() {

    this.disconnectMobile()
    this.disconnect()

  }

  lock() {
    document.body.requestPointerLock()
  }

  unlock() {
    document.exitPointerLock()
  }

  onPointerlockChange = () => {
    if (document.pointerLockElement) {
      this.dispatchEvent(this.lockEvent)

      this.isLocked = true
    } else {
      this.dispatchEvent(this.unlockEvent)

      this.isLocked = false
    }
  }

  onPointerlockError = () => {
    console.error('PointerLockControlsCannon: Unable to use Pointer Lock API')
  }

  onMouseMove = (event) => {
    if (!this.enabled) {
      return
    }


    const { movementX, movementY } = event
    // this.movementsObj.x = this.lerp(this.movementsObj.x, movementX, 0.015)
    // this.movementsObj.y = this.lerp(this.movementsObj.y, movementY, 0.015) 
    // this.yawObject.rotation.y -= this.movementsObj.x * 0.002
    // this.pitchObject.rotation.x -= this.movementsObj.y * 0.002
    // this.pitchObject.rotation.x = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, this.pitchObject.rotation.x))


    if(movementX > -100 && movementX < 100 && movementY > - 100 && movementY < 100) {
  
      this.yawObject.rotation.y -= movementX * 0.002
      this.pitchObject.rotation.x -= movementY * 0.002
      this.pitchObject.rotation.x = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, this.pitchObject.rotation.x))
    } else {

      return
    }
  
  }
  onTouchEnd = (e) => {
    this.createMobileControls()
    this.onDirectionTouchMove();
    // this.lastxpos = this.yawObject.rotation.y;
    // this.lastypos = this.pitchObject.rotation.x;
    // this.lastClientX = this.clientX
    // this.lastClientY = this.clientY

    this.eulerY_final = this.euler.y;
		this.eulerX_final = this.euler.x;
		this.eulerY_angle = [];
		this.eulerX_angle = [];
		this.array_x = [];
		this.array_y = [];

    this.dispatchEvent(this.changeEvent);
    // console.log("dernières positions:" , this.lastxpos, this.lastypos, this.lastClientX, this.lastClientY)
  };

  onTouch(){
  }	


  arrayTouches = (e) => {
		// array for touches
		this.xfromtouch = e.changedTouches[0].clientX - this.ww
		this.yfromtouch = e.changedTouches[0].clientY - this.wh

		this.array_x.push(this.xfromtouch)
		this.array_y.push(this.yfromtouch)

		this.last_x = this.array_x[this.array_x.length - 2];
		this.last_y = this.array_y[this.array_y.length - 2];


		// array for euler
		this.eulerY = (this.xfromtouch - this.last_x) * 0.009;
		this.eulerX = (this.yfromtouch - this.last_y) * 0.008;


		if (this.eulerY) {
			this.eulerY_angle.push(this.eulerY)
			this.eulerX_angle.push(this.eulerX)
		} else {
			this.eulerY_angle.push(0);
			this.eulerX_angle.push(0);
		}

		this.dispatchEvent(this.changeEvent);
	}

 
  onTouchMove = (e) => {
    
    // this.direction.hide()
    
    // if(this.direction[0] !== undefined) {
    //   this.direction.destroy()
    // }

     if(this.direction[0]) {
      this.direction.destroy()
    }



    // this.clientY = e.touches[0].clientY;
    // this.clientX = e.touches[0].clientX;
    // this.xfromtouch = this.clientX-this.ww;
    // this.yfromtouch = this.clientY-(this.h-100) ;
    // this.pitchObject.rotation.x = this.yfromtouch * 0.009
    // this.pitchObject.rotation.x =  Math.max( - Math.PI / 2, Math.min( Math.PI / 2, this.pitchObject.rotation.x ) );
    // this.pitchObject.rotation.y = this.xfromtouch * 0.008
    // this.yawObject.rotation.y =  Math.max( - Math.PI, Math.min( Math.PI, this.yawObject.rotation.y ) );
    
    this.eulerY_total = this.eulerY_angle.reduce((sum, element) => {
			return sum + element;
		}, 0);
		this.eulerX_total = this.eulerX_angle.reduce((sum, element) => {
			return sum + element;
		}, 0);
  
    //camera
    this.yawObject.rotation.y = this.eulerY_final + this.eulerY_total 
    //body
    this.pitchObject.rotation.x = this.eulerX_final + this.eulerX_total
    this.dispatchEvent(this.changeEvent);
  }

  onDirectionTouchMove() {
    this.direction.on('start end', (evt, data) => {
                
      if (evt.type === "end") {
        this.moveForward = false
        this.moveBackward = false
        this.moveRight = false
        this.moveLeft = false
        this.reconnectMob();
        
      }

    }).on('move', (evt, data) => {

      this.disconnectMobile();
      
      if(data.direction === undefined) {
      
            this.disconnectMobile();
            this.moveForward = false
            this.moveBackward = false
            this.moveRight = false
            this.moveLeft = false
            this.direction.destroy()
            this.createMobileControls()
            this.onDirectionTouchMove();
            this.reconnectMob()
      
           
           return;
      } 

      // this.disconnectMobile();


      else if(data.direction.angle === "up") { 
        this.moveForward = true
        this.moveBackward = false
        this.moveRight = false
        this.moveLeft = false
      }

      else if(data.direction.angle === "down") {
        this.moveBackward = true
        this.moveForward = false
        this.moveRight = false
        this.moveLeft = false
      }

      else if(data.direction.angle === "right") {
        this.moveRight = true
        this.moveLeft = false
        this.moveForward = false
        this.moveBackward = false
      }
      else if(data.direction.angle === "left") {
        this.moveLeft = true
        this.moveRight = false
        this.moveForward = false
        this.moveBackward = false
      }
    })
  }

  // onOrientationTouchMove() {

  //   this.orientation.on('start end', (evt, data) => {
                
  //     if (evt.type === "end") {
  //         this.yawObject.rotation.y -= 0;  
  //         this.pitchObject.rotation.x -= 0;
          
  //     }

  //   }).on('move', (evt, data) => {

  //     console.log(evt)

  //     // this.clientY = evt.target.box.y;
  //   // this.clientX = evt.target.box.x;
  //   // this.xfromtouch = this.clientX-this.ww;
  //   // this.yfromtouch = this.clientY-(this.h-100) ;

  //     if(data.direction.angle === "up") {
  //       this.pitchObject.rotation.x += 0.009;
  //       this.pitchObject.rotation.x = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, this.pitchObject.rotation.x))

  //     }

  //     if(data.direction.angle === "down") {
  //       this.pitchObject.rotation.x -= 0.008;
  //       this.pitchObject.rotation.x = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, this.pitchObject.rotation.x))
  //     }

  //     if(data.direction.angle === "right") {
  //       this.yawObject.rotation.y -= 0.01;
  //       this.yawObject.rotation.y =  Math.max( - Math.PI, Math.min( Math.PI, this.yawObject.rotation.y ) );
  //     }
  //     if(data.direction.angle === "left") {
  //       this.yawObject.rotation.y += 0.01;
  //       this.yawObject.rotation.y =  Math.max( - Math.PI, Math.min( Math.PI, this.yawObject.rotation.y ) );
  //     }
  // })

  // }

  onTouchDown(data) {
    switch(data.direction.angle) {
       case 'up':
        this.moveForward = true
        break

       case 'left':
        this.moveLeft = true
        break

       case 'right':
        this.moveRight = true
        break
       
       case 'down':
        this.moveBackward = true
        break
    }
  }


  onKeyDown = (event) => {
    switch (event.code) {
      case 'KeyW':
      case 'KeyZ':
      case 'ArrowUp':
        this.moveForward = true
        break

      case 'KeyQ':
      case 'KeyA':
      case 'ArrowLeft':
        this.moveLeft = true
        break

      case 'KeyS':
      case 'ArrowDown':
        this.moveBackward = true
        break

      case 'KeyD':
      case 'ArrowRight':
        this.moveRight = true
        break

      case 'Space':
        if (this.canJump) {
          this.velocity.y = this.jumpVelocity
        }
        this.canJump = false
        break
    }
  }

  onKeyUp = (event) => {
    switch (event.code) {
      case 'KeyW':
      case 'KeyZ':
      case 'ArrowUp':
        this.moveForward = false
        break

      case 'KeyQ':
      case 'KeyA': 
      case 'ArrowLeft':
        this.moveLeft = false
        break

      case 'KeyS':
      case 'ArrowDown':
        this.moveBackward = false
        break

      case 'KeyD':
      case 'ArrowRight':
        this.moveRight = false
        break
    }
  }

  getObject() {
    return this.yawObject
  }

  getDirection() {
    const vector = new CANNON.Vec3(0, 0, -1)
    vector.applyQuaternion(this.quaternion)
    return vector
  }

  update(delta, mobileMode) {

    if(mobileMode === false) {
      if (this.enabled === false) {
      return
      }
    }
    

    delta *= 1000
    delta *= 0.1

    this.inputVelocity.set(0, 0, 0)

    if(this.moveForward) {
      this.inputVelocity.z = -this.velocityFactor * delta

    }
    if (this.moveBackward) {
      this.inputVelocity.z = this.velocityFactor * delta
    }

    if (this.moveLeft) {
      this.inputVelocity.x = -this.velocityFactor * delta
    }
    if (this.moveRight) {
      this.inputVelocity.x = this.velocityFactor * delta
    }

    // Convert velocity to world coordinates
    this.euler.x = this.pitchObject.rotation.x
    this.euler.y = this.yawObject.rotation.y
    this.euler.order = 'XYZ'
    this.quaternion.setFromEuler(this.euler)
    this.inputVelocity.applyQuaternion(this.quaternion)

    // Add to the object
    this.velocity.x += this.inputVelocity.x
    this.velocity.z += this.inputVelocity.z

    this.yawObject.position.copy(this.cannonBody.position)
  
   
  }

 
}

export { PointerLockControlsCannon }