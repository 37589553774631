import(/* webpackPreload: true */ '../css/components/styles.scss')
import { Starting } from './Starting';


const newStart = new Starting();

window.addEventListener('load', () => {

   newStart.homepageTrigger().then(() => {
       
       return new Promise((resolve) => {

        setTimeout(() => {
            resolve(newStart.instantiateWorker());
            }, 3000)
        })
    });


})



// window.addEventListener("DOMContentLoaded", (e) => {
   

// })