import { Raycaster, Vector2 } from "three/build/three.module";

export class RaycasterHover {
    constructor(camera, scene) {
    
        this.camera = camera;
        this.scene = scene;
        this.crosshair = document.querySelector('.crosshair')
        this.raycaster = new Raycaster();
        this.intersects;

        this.bandoContact = this.scene.getObjectByProperty('name', 'bando_1');
        this.contactTxt = this.scene.getObjectByProperty('name', 'contact_me001');
        this.contactBtn = this.scene.getObjectByProperty('name', 'contact-btn001');
        this.objectsList = [this.bandoContact, this.contactBtn, this.contactTxt];
        this.mousePos = new Vector2(0, 0);     


   }

   get raycasterEl() {
    return this.raycaster;
   }

   rayCaster(anim, controls, mobileMode) {
       
        this.raycaster.setFromCamera(this.mousePos, this.camera);
        this.intersects = this.raycaster.intersectObjects(this.scene.children, true);
        

        // if(this.intersects[0]) {
        //     console.log(this.intersects[0].object.name)   
        // }

        if(this.raycaster.far === Infinity) {
            if(this.intersects[0].object.getObjectByProperty('name', 'bando_1')) {
                anim.menuOpen(controls, mobileMode);
                this.raycaster.far = 0;
            }
    
            if(this.intersects[0].object.getObjectByProperty('name', 'contact_me001')) {
                anim.menuOpen(controls, mobileMode);
                this.raycaster.far = 0;
            }
    
            if(this.intersects[0].object.getObjectByProperty('name', 'contact-btn001')) {
                anim.menuOpen(controls, mobileMode);
                this.raycaster.far = 0;
            }
        } else {
            return;
        }

        

    }

   rayCasterHover() {

    this.raycaster.setFromCamera(this.mousePos, this.camera);
    this.intersects = this.raycaster.intersectObjects(this.objectsList, true);
    
    if (this.intersects[0]) {
        this.crosshair.classList.add('hit')
        this.crosshair.classList.remove('base')
    }

    else {
        this.crosshair.classList.add('base')
        this.crosshair.classList.remove('hit')
       
        }
    }
}