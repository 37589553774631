export class Stats {
    constructor() {
        this.startStats();
    }

    // startStats() {
    //     if(!sessionStorage.getItem("_swa")&&document.referrer.indexOf(location.protocol+"//"+location.host)!== 0){fetch("https://counter.dev/track?"+new URLSearchParams({referrer:document.referrer,screen:screen.width+"x"+screen.height,user:"Nikausse",utcoffset:"1"}))};sessionStorage.setItem("_swa","1");
    // }
    startStats() {
        this.script = document.createElement('script');
        this.script.type = 'text/javascript';
        this.script.async = true;
        this.script.src = 'https://www.googletagmanager.com/gtag/js?id=G-K8VVD4FDY6';    
        document.body.appendChild(this.script);

        this.script2 = document.createElement('script');
        this.script2.type = 'text/javascript';
        this.script2.innerHTML = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('set', 'allow_google_signals', false);
        gtag('set', 'allow_ad_personalization_signals', false);
        gtag('config', 'G-K8VVD4FDY6', { client_storage: 'none', 'anonymize_ip': true });`
    
        document.body.appendChild(this.script2);

    }
}